// constants
import Web3EthContract from 'web3-eth-contract';
import Web3 from 'web3';
import SmartContract from '../../contracts/ABI.json';
// log
import { fetchData } from '../data/dataActions';

const connectRequest = () => {
  return {
    type: 'CONNECTION_REQUEST'
  };
};

const connectSuccess = (payload) => {
  return {
    type: 'CONNECTION_SUCCESS',
    payload: payload
  };
};

const connectFailed = (payload) => {
  return {
    type: 'CONNECTION_FAILED',
    payload: payload
  };
};

const updateAccountRequest = (payload) => {
  return {
    type: 'UPDATE_ACCOUNT',
    payload: payload
  };
};

export const updateAccount = (account) => {
  return async (dispatch) => {
    dispatch(updateAccountRequest({ account: account }));
    dispatch(fetchData(account));
  };
};

export const connect = () => {
  return async (dispatch) => {
    try {
      dispatch(connectRequest());
      const { ethereum } = window;
      const metamaskIsInstalled = ethereum && ethereum.isMetaMask;
      await window.ethereum.request({
        method: 'eth_requestAccounts'
      });
      if (metamaskIsInstalled) {
        Web3EthContract.setProvider(ethereum);
        let web3 = new Web3(ethereum);
        try {
          const accounts = await ethereum.request({
            method: 'eth_requestAccounts'
          });
          const networkId = await ethereum.request({
            method: 'net_version'
          });
          // const NetworkData = await SmartContract.networks[networkId];
          if (networkId == 4) {
            const SmartContractObj = new Web3EthContract(
              SmartContract,
              '0x5D9972E695Cf8BAaf1b2Ca79170039A8fE5a93d9'
            );
            dispatch(
              connectSuccess({
                account: accounts[0],
                smartContract: SmartContractObj,
                web3: web3
              })
            );
            // Add listeners start
            ethereum.on('accountsChanged', (accounts) => {
              dispatch(updateAccount(accounts[0]));
            });
            ethereum.on('chainChanged', () => {
              window.location.reload();
            });
            // Add listeners end
          } else {
            dispatch(connectFailed('Change network to Ethereum'));
          }
        } catch (err) {
          dispatch(connectFailed('Something went wrong'));
        }
      } else {
        dispatch(connectFailed('Install Metamask'));
      }
    } catch (err) {
      dispatch(connectFailed('Please Fix Metamask'));
    }
  };
};
